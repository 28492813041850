import BaseInstance from "../base.instance";
import {
  PRESENSI_SCHEDULE_MANUAL_LIST,
  PRESENSI_SCHEDULE_MANUAL_DETAIL,
  PRESENSI_SCHEDULE_MANUAL_SAVE,
  PRESENSI_SCHEDULE_MANUAL_DELETE
} from "../constants";

const AttendanceManualService = {
  getList(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_MANUAL_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(PRESENSI_SCHEDULE_MANUAL_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_MANUAL_SAVE, data);
  },
  delete(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_MANUAL_DELETE, data);
  }
};

export default AttendanceManualService;
