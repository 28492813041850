<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Penjadwalan Manual</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" id="card-custom">
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Utama"
                :items="unitList"
                v-model="filter.unit_utama_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnitList(0)"
                @click:clear="changeUnitList(0)"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Kerja Group"
                :items="unitList1"
                v-model="filter.uk_group_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_uk_group"
                item-value="uk_group_id"
                @change="changeUnitList(1)"
                @click:clear="changeUnitList(1)"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Kerja"
                :items="unitList2"
                v-model="filter.unit_kerja_2_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-text-field
                v-model="filter.search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row id="card-custom">
            <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="
                  form.id = null;
                  visible = true;
                "
                >Tambah Jadwal Manual<v-icon class="ml-2" color="primary"
                  >add_circle</v-icon
                ></v-btn
              >
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12"> </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="holidays"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            item-key="no"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              <span v-if="item.created_at">{{
                item.created_at.date | date
              }}</span>
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <div class="py-1">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.nama_unit_utama }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja Group: </b>{{ item.nama_uk_group }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja: </b>{{ item.nama_unit_kerja_2 }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Edit</v-btn
              >
              <v-btn
                x-small
                color="#FBB005"
                class="my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions v-if="selected.length > 0">
      <p class="subtitle-2 font-weight-regular ma-3 headline-color">
        {{ selected.length }} TERPILIH
      </p>
      <v-btn
        small
        outlined
        color="#717171"
        class="mx-2 px-5 text-capitalize"
        @click="handleDelete(null, true)"
        >Hapus</v-btn
      >
    </v-card-actions>
    <EmployeeSearchDialog
      ref="employeeSearch"
      :lists="form.details.employees"
      value="NIP"
      @handle-pick="pickItem"
    />
    <GroupNIPSearchDialog
      ref="groupNIPSearch"
      :lists="[]"
      value="groupid"
      @handle-pick="pickGroupItem"
    />
    <v-dialog v-model="visible" persistent max-width="800">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Penjadwalan
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-text-field
                v-model="form.manual_ket"
                outlined
                dense
                :rules="[v => !!v || 'Nama harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Deskripsi</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-text-field
                v-model="form.description"
                outlined
                dense
                :rules="[v => !!v || 'Deskripsi harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-0">
              <v-autocomplete
                v-model="form.unit_utama_id"
                :items="units"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnit(0)"
                @click:clear="changeUnit(0)"
                :rules="[v => !!v || 'Unit utama harus diisi']"
                :disabled="form.id != null"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja Group</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-0">
              <v-autocomplete
                v-model="form.uk_group_id"
                :items="units1"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="nama_uk_group"
                item-value="uk_group_id"
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
                :rules="[v => !!v || 'Unit kerja group harus diisi']"
                :disabled="form.id != null"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-0">
              <v-autocomplete
                v-model="form.unit_kerja_2_id"
                :items="units2"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                :disabled="form.id != null"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row>
            <v-col cols="12" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >List Pegawai</v-subheader
              >
              <v-data-table
                id="table-custom-pegawai"
                class="mt-3"
                :headers="employeeHeaders"
                :items="form.details.employees"
              >
                <template v-slot:[`item`]="{ item, index }">
                  <tr>
                    <td class="pt-2">
                      {{ item.NIP }}
                    </td>
                    <td class="pt-2">
                      {{ item.name }}
                    </td>
                    <td>
                      <v-btn
                        small
                        color="#FBB005"
                        class="ml-2 white--text"
                        @click="deleteItemEmployee(index)"
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="addEmployee"
                  >Tambah<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="$refs.groupNIPSearch.change(true)"
                  >Tambah Group NIP<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row>
            <v-col cols="12" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >List Jadwal</v-subheader
              >
              <v-data-table
                id="table-custom"
                class="mt-3"
                :headers="attendanceHeaders"
                :items="form.details.attendances"
              >
                <template v-slot:[`item`]="{ item, index }">
                  <tr>
                    <td>
                      <v-subheader
                        class="caption headline-color font-weight-regular"
                        >{{ index + 1 }}</v-subheader
                      >
                    </td>
                    <td class="pt-2">
                      <v-text-field
                        v-model="item.manual_date"
                        type="date"
                        outlined
                        dense
                        :rules="[v => !!v || 'Tanggal harus diisi']"
                      ></v-text-field>
                    </td>
                    <td class="pt-2">
                      <v-autocomplete
                        :items="scheduleUnits"
                        outlined
                        dense
                        v-model="item.schedule_id"
                        :loading="loadingScheduleUnit"
                        :rules="[v => !!v || 'Unit jadwal harus diisi']"
                        item-text="schedule_name"
                        item-value="schedule_id"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-btn
                        small
                        color="#FBB005"
                        class="ml-2 white--text"
                        @click="deleteItem(index)"
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="addSchedule"
                  >Tambah<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";
import AttendanceManualService from "@/services/resources/attendance.manual.service";
import ScheduleUnitService from "@/services/resources/schedule-unit.service";

const EmployeeSearchDialog = () => import("@/components/Dialog/EmployeeSearch");
const GroupNIPSearchDialog = () => import("@/components/Dialog/GroupNIPSearch");

export default {
  components: {
    EmployeeSearchDialog,
    GroupNIPSearchDialog
  },
  data() {
    return {
      visible: false,
      valid: false,
      loading: false,
      loadingUnit: false,
      loadingScheduleUnit: false,
      formLoading: false,
      filter: {
        search: null,
        unit_utama_id: null,
        uk_group_id: null,
        unit_kerja_2_id: null
      },
      unitList: [],
      unitList1: [],
      unitList2: [],
      units: [],
      units1: [],
      units2: [],
      scheduleUnits: [],
      selected: [],
      selection: true,
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false
        },
        {
          text: "Nama",
          value: "manual_ket",
          sortable: false
        },
        {
          text: "Deskripsi",
          value: "description",
          sortable: false
        },
        {
          text: "Unit Kerja",
          value: "unit",
          sortable: false
        },
        {
          text: "Tanggal Buat",
          value: "created_at",
          sortable: false
        },
        { text: "", value: "actions", sortable: false }
      ],
      employeeHeaders: [
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center"
        },
        {
          text: "Nama",
          value: "name",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ],
      attendanceHeaders: [
        {
          text: "No",
          value: "no",
          sortable: false,
          align: "center"
        },
        {
          text: "Tanggal",
          value: "manual_date",
          sortable: false,
          align: "center"
        },
        {
          text: "Unit Jadwal",
          value: "schedule_id",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ],
      holidays: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["manual_id"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        manual_id: null,
        manual_ket: null,
        description: null,
        unit_utama_id: null,
        uk_group_id: null,
        unit_kerja_2_id: null,
        details: {
          employees: [],
          attendances: []
        }
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListSchedule);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListSchedule);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
        this.form.details.employees = [];
        this.form.details.attendances = [];
      }
    }
  },
  computed: {
    paginationProperty() {
      return [
        this.filter.search,
        this.filter.unit_utama_id,
        this.filter.uk_group_id,
        this.filter.unit_kerja_2_id
      ].join();
    }
  },
  methods: {
    changeUnit(type = 0) {
      if (type == 0) {
        this.units1 = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja_group;
        this.form.uk_group_id = null;
        this.form.unit_kerja_2_id = null;
      } else if (type == 1) {
        this.units2 = this.units1.find(
          d => d.uk_group_id == this.form.uk_group_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    changeUnitList(type = 0) {
      if (type == 0) {
        this.unitList1 = this.unitList.find(
          d => d.unit_utama_id == this.filter.unit_utama_id
        )?.unit_kerja_group;
        this.filter.uk_group_id = null;
        this.filter.unit_kerja_2_id = null;
      } else if (type == 1) {
        this.unitList2 = this.unitList1.find(
          d => d.uk_group_id == this.filter.uk_group_id
        )?.unit_kerja2;
        this.filter.unit_kerja_2_id = null;
      }
    },
    populateUnit() {
      this.units1 = this.units.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja_group;
      this.units2 = this.units1.find(
        d => d.uk_group_id == this.form.uk_group_id
      )?.unit_kerja2;
    },
    deleteItem(index) {
      this.form.details.attendances.splice(index, 1);
    },
    deleteItemEmployee(index) {
      this.form.details.employees.splice(index, 1);
    },
    handleRemoveEmployee(item) {
      let index = this.form.details.employees.findIndex(d => d.NIP == item.NIP);
      this.form.details.employees.splice(index, 1);
    },
    addSchedule() {
      this.form.details.attendances.push({
        manual_date: null,
        schedule_id: null
      });
    },
    addEmployee() {
      this.$refs.employeeSearch.change(true);
    },
    pickItem(item) {
      if (item) {
        let index = this.form.details.employees.findIndex(
          d => d.NIP == item.NIP
        );
        if (index < 0) {
          this.form.details.employees.push({
            NIP: item.NIP,
            name: item.nama
          });
        }
      }
    },
    pickGroupItem(item) {
      if (item) {
        item.pegawaiList.map(pegawai => {
          this.pickItem(pegawai);
        });
      }
    },
    handleEdit(item) {
      this.visible = true;
      this.getDetailSchedule(item.manual_id);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Hapus Jadwal",
        message: `Apakah Anda yakin akan menghapus jadwal ini?`,
        button: {
          no: "Batal",
          yes: "Hapus"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteAttendance(item.manual_id);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        console.log("masuk");
        if (this.form.details.attendances.length < 1) {
          this.$store.commit("snackbar/setSnack", {
            show: true,
            message: "Masukan daftar kehadiran minimal 1.",
            color: "error"
          });
          return;
        }
        let payload = {
          action: this.form.manual_id ? 2 : 1,
          manual_id: this.form.manual_id,
          manual_ket: this.form.manual_ket,
          description: this.form.description,
          unit_utama_id: this.form.unit_utama_id,
          uk_group_id: this.form.uk_group_id,
          unit_kerja_2_id: this.form.unit_kerja_2_id,
          details: {
            employees: this.form.details.employees,
            attendances: this.form.details.attendances
          }
        };
        this.saveAttendance(payload);
      }
    },
    // Service
    async getListScheduleUnit() {
      try {
        this.loadingScheduleUnit = true;
        await ScheduleUnitService.getListActive()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.scheduleUnits = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingScheduleUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnitKerjaGroup() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaGroupLOV(0)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              console.log(data);
              this.units = data;
              this.unitList = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListSchedule() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await AttendanceManualService.getList({
          filter: {
            search: this.filter.search,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let holidays = list;
              holidays.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.holidays = holidays;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveAttendance(data) {
      try {
        this.formLoading = true;
        await AttendanceManualService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListSchedule();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailSchedule(id) {
      try {
        this.loading = true;
        await AttendanceManualService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form = this.$_.merge(this.form, data);
              this.form.details.employees = data.details.employees;
              this.form.details.attendances = data.details.attendances;
              console.log(this.form);
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteAttendance(id) {
      try {
        this.formLoading = true;
        await AttendanceManualService.delete({
          manual_id: id
        })
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListSchedule();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnitKerjaGroup();
    this.getListScheduleUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
